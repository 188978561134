module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Tudor Clothing School - New Zealand made school uniforms","short_name":"Tudor Clothing School","start_url":"/","background_color":"#000","theme_color":"#000","display":"standalone","icon":"src/images/tudor-icon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fb65362d484a5c4d20a61fe6b7c80660"},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"crumbLabelUpdates":[{"pathname":"/about-us","crumbLabel":"About us"},{"pathname":"/about-us/ethos","crumbLabel":"Ethos"},{"pathname":"/schools","crumbLabel":"Schools"},{"pathname":"/schools/balmacewen-intermediate","crumbLabel":"Balmacewen Intermediate"},{"pathname":"/schools/columba-college","crumbLabel":"Columba College"},{"pathname":"/schools/john-mcglashan-college","crumbLabel":"John McGlashan College"},{"pathname":"/schools/kaikorai-valley-college","crumbLabel":"Kaikorai Valley College"},{"pathname":"/schools/otago-boys-high-school","crumbLabel":"Otago Boys High School"},{"pathname":"/schools/otago-girls-high-school","crumbLabel":"Otago Girls High School"},{"pathname":"/schools/queens-high-school","crumbLabel":"Queens High School"},{"pathname":"/schools/st-hildas-collegiate","crumbLabel":"St Hildas Collegiate School"},{"pathname":"/contact-us","crumbLabel":"Contact us"}],"exclude":["/dev-404-page/","/404/","/404.html","/offline-plugin-app-shell-fallback/"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
