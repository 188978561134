// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-ethos-js": () => import("./../../../src/pages/about-us/ethos.js" /* webpackChunkName: "component---src-pages-about-us-ethos-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-schools-balmacewen-intermediate-js": () => import("./../../../src/pages/schools/balmacewen-intermediate.js" /* webpackChunkName: "component---src-pages-schools-balmacewen-intermediate-js" */),
  "component---src-pages-schools-columba-college-js": () => import("./../../../src/pages/schools/columba-college.js" /* webpackChunkName: "component---src-pages-schools-columba-college-js" */),
  "component---src-pages-schools-john-mcglashan-college-js": () => import("./../../../src/pages/schools/john-mcglashan-college.js" /* webpackChunkName: "component---src-pages-schools-john-mcglashan-college-js" */),
  "component---src-pages-schools-js": () => import("./../../../src/pages/schools.js" /* webpackChunkName: "component---src-pages-schools-js" */),
  "component---src-pages-schools-kaikorai-valley-college-js": () => import("./../../../src/pages/schools/kaikorai-valley-college.js" /* webpackChunkName: "component---src-pages-schools-kaikorai-valley-college-js" */),
  "component---src-pages-schools-otago-boys-high-school-js": () => import("./../../../src/pages/schools/otago-boys-high-school.js" /* webpackChunkName: "component---src-pages-schools-otago-boys-high-school-js" */),
  "component---src-pages-schools-otago-girls-high-school-js": () => import("./../../../src/pages/schools/otago-girls-high-school.js" /* webpackChunkName: "component---src-pages-schools-otago-girls-high-school-js" */),
  "component---src-pages-schools-queens-high-school-js": () => import("./../../../src/pages/schools/queens-high-school.js" /* webpackChunkName: "component---src-pages-schools-queens-high-school-js" */),
  "component---src-pages-schools-st-hildas-collegiate-js": () => import("./../../../src/pages/schools/st-hildas-collegiate.js" /* webpackChunkName: "component---src-pages-schools-st-hildas-collegiate-js" */)
}

